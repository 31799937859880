export const URLs = {
    home: "/",

    // Frontend
    textyle: "/textyle",
    fwd: "/fwd",
    booking: "/booking",
    enterfront: "/enterfront",

    // Backend
    verilog: "/verilog",
    sna: "/sna",
    enigma: "/enigma",
    game: "/game",
    school: "/school",
    compiler: "/compiler",

    // Hackathons
    naimix: "/naimix"
}